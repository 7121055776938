@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@400;800;900);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@font-face {
  font-family: "Artifact";
  src: url("https: //latinacerts.s3.us-west-1.amazonaws.com/Artifact.woff") format("woff");
  font-weight: normal;
}

:root {
  --dark: #000;
  --blue: #becae1;
  --pink: #edc0d3;
  --gray: #2c2c2c;
  --blue: #d4ebf8;
  --accent: #ea638c;
  --butter: #f1e1b0;
  --yellow: #efe0a3;
  --orange: #fbd5b7;
  --success: #c7e1c5;
  --primary: #d4ebf8;
}

thead tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

th,
td {
  min-width: 100px;
}

span,
p,
div,
a,
input,
body,
html {
  font-family: "Archivo", sans-serif;
  z-index: 1;
}

.artifact {
  text-transform: none;
  font-family: "Artifact", sans-serif !important;
}

body {
  overflow-x: hidden;
  max-width: 100vw;
}

nav {
  max-width: 100vw;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Archivo", sans-serif;
  text-transform: uppercase;
  font-weight: 800;
}

.alert {
  z-index: 1000;
}

.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

img.thumbnail {
  max-width: 16px;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.text-butter {
  color: #f1e1b0;
  color: var(--butter);
}

.text-vxn {
  color: #ff1399;
}

.text-latina {
  color: var(--sand);
}

.text-montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.panel-mobile-menu {
  display: none;
}

.panel-mobile-menu img {
  max-width: 100px;
}

.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.vh-100 {
  height: auto !important;
  min-height: 100vh;
}

.capitalize {
  text-transform: capitalize;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  border: 2px solid var(--sand);
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.schedule-class {
  border-radius: 10px;
}

.class-container {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.package-card {
  height: 350px;
}

.schedule-class {
  cursor: pointer;
}

.schedule-class:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-container {
  width: 170%;
}

.border-primary {
  border-width: 3px !important;
  border-color: #d4ebf8 !important;
  border-color: var(--primary) !important;
}

.card {
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transition: all 0.25s;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.no-scale:hover {
  -webkit-transform: none !important;
          transform: none !important;
}

.payment-method {
  max-width: 75px;
}

.color-legend {
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.btn-outline-primary {
  border-color: var(--sand);
  color: var(--sand);
}

.btn-outline-primary:hover {
  background-color: var(--sand);
  border: var(--sand);
}

.user-card {
  position: absolute;
  width: 290px;
  right: 12px;
  transition: all 0.2s;
  position: absolute !important;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 20px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 100%;
  height: 75vh;
}

.section-container {
  min-height: 100vh;
  overflow-y: scroll;
}

.section-icon {
  max-width: 200px;
}

.course-info-img {
  height: 100vh;
  object-fit: cover;
  margin-left: -12px;
  width: calc(100% + 24px);
}

.info-img {
  width: 100%;
  z-index: 1;
  max-width: 110%;
}

img.section-thumbnail {
  max-width: 35px;
}

.thumbnail {
  max-width: 100px;
}

.crossed  {
  text-decoration: line-through;
}

.bg-primary {
  background-color: #d4ebf8 !important;
  background-color: var(--blue) !important;
}

.bg-success {
  background-color: #c7e1c5 !important;
  background-color: var(--success) !important;
  color: #000 !important;
  color: var(--dark) !important;
}

.bg-warning {
  color: #000 !important;
  color: var(--dark) !important;
}

.bg-pink {
  background-color: #d4ebf8;
  background-color: var(--primary);
}

.bg-blue {
  background-color: #d4ebf8;
  background-color: var(--blue);
}

.alert-pink {
  background-color: rgba(237, 192, 211, 0.5);
}

.adilo-player {
  height: 70vh;
  width: 100%;
}

.btn-login i {
  position: absolute;
  z-index: 1;
  left: calc(50% - 36px);
  top: calc(50% - 32px);
}

.btn-login .fa-heart {
  font-size: 72px;
}

.btn-login span {
  z-index: 10;
}

.btn-primary {
  background-color: #d4ebf8 !important;
  background-color: var(--primary) !important;
  border: 1px solid #000 !important;
  border: 1px solid var(--dark) !important;
  color: #000 !important;
  color: var(--dark) !important;
}

.btn-primary:focus {
  background-color: #000;
  box-shadow: none;
  box-shadow: none;
}

.btn-primary:hover {
  background-color: #000;
}

.btn-primary:active {
  background-color: #000;
  box-shadow: none;
}

.btn-primary:active {
  background-color: #000;
  box-shadow: none;
}

.about-latina {
  min-height: 500px;
  padding: 32px;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 1.4em;
}
.bg-nude {
  background-color: var(--sand);
}

html {
  width: 100%;
  max-width: 100%;
}

.text-english {
  font-family: "English", sans-serif !important;
  font-size: 54px;
}

.menu-category {
  font-size: 1.2rem;
  width: 100%;
}

.menu-category:hover {
  background-color: var(--sand);
}

.menu-category:active {
  box-shadow: none;
  background-color: var(--sand);
}

.menu-category:focus {
  box-shadow: none;
  background-color: var(--sand);
}

.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.menu-category .my-2 {
  background-color: var(--sand);
}

.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.closed {
  height: 0px;
}

body {
  width: 100%;
  max-width: 100%;
}

@font-face {
  font-family: Brittany;
  src: url(/static/media/BrittanySignature.0d66644a.otf);
}

@font-face {
  font-family: English;
  src: url(/static/media/english.8957803e.otf);
}

.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.nav-item {
  height: 60px;
  padding-left: 8px;
  padding-right: 8px;
  color: #fff;
  transition: all 0.25s;
  font-weight: 800;
  font-size: 0.8em;
}

.navbar-cta {
  margin-top: 10px;
  transition: all 0.25s;
}

.navbar-cta:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.hover-pink:hover {
  background-color: #d4ebf8;
  background-color: var(--primary);
  color: #000;
  color: var(--dark);
}

.hover-pink:hover a {
  color: #000 !important;
  color: var(--dark) !important;
}

.hover-blue:hover {
  background-color: #d4ebf8;
  background-color: var(--blue);
  color: #000;
  color: var(--dark);
}

.hover-blue:hover a {
  color: #000 !important;
  color: var(--dark) !important;
}

.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  font-size: 18px;
  letter-spacing: 0.5px;
  display: inline-block;
  margin-top: 8px;
  color: #fff !important;
}

.course-navigation {
  width: 25%;
  z-index: 1050;
  height: 100vh;
  position: fixed;
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.single-section {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.bg-dark {
  background-color: #000 !important;
}

.bg-vertical {
  background-image: url("https://latinacerts.s3.us-west-1.amazonaws.com/collage-min.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.text-nude {
  color: var(--sand);
}

.btn {
  text-transform: uppercase;
  font-weight: 800;
}

.text-funny {
  font-family: "Brittany", sans-serif !important;
  font-size: 4rem;
}

.text-header {
  letter-spacing: 0.5px;
  font-size: 20px;
  text-transform: uppercase;
}

.text-left {
  text-align: left !important;
}

.side-menu {
  height: 100vh;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  width: 200px;
  background-color: #191919;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 64px;
  
}

.side-menu-list {
  list-style-type: none;
  padding-left: 0px;
}

.side-menu-list .nav-item {
  padding-left: 0px;
  padding-right: 0px;
}

.main-panel {
  background: #fff;
  min-height: 100vh;
  margin-left: 200px;
}

.main-panel * {
  z-index: 1;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--sand);
  color: #fff;
}

.logo-footer {
  max-width: 250px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}

.content {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.course-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.course-meta {
  height: 150px;
  overflow: hidden;
}

.lesson-title {
  height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course-desc {
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-image {
  max-width: 300px;
}

.profile-thumbnail {
  border-radius: 100px;
  max-width: 100%;
}

.video-thumbnail {
  height: 200px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.card-video {
  border: 3px solid black;
  padding-bottom: 16px;
  margin-bottom: 32px;
  transition: all 1s;
  height: 330px;
}

.card-video p {
  font-size: 14px;
}

.card-video h3 {
  font-size: 16px;
}

.panel-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
  font-size: 24px;
}

.filter-card {
  border-radius: 10px;
  padding: 16px;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.latest-card {
  height: 400px;
  margin-bottom: 32px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
}

.latest-card-thumbnail {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  font-size: 20px;
}

.user {
  background-color: var(--sand);
  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}

.name-row:focus {
  background-color: var(--light);
}

.home-video {
  height: 90vh;
  object-fit: cover;
}

#video-row {
  margin-top: 60px !important;
}

.logo-navbar {
  max-width: 45px;
}

.vh-100 {
  height: auto !important;
  min-height: 100vh;
}

#login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.panel-content {
  min-height: 100vh;
  margin-left: -15px;
  margin-right: -15px;
  padding: 32px;
}

.lesson-wrapper {
  padding-top: 1.5rem;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.user-menu {
  margin-left: -50%;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
  text-transform: uppercase;
}

.br-0 {
  border-radius: 0px !important;
}

.drawer-menu {
  z-index: 10;
  position: absolute;
  transition: all 0.25s;
}

.nav-item .mobile:focus {
  background-color: var(--sand);
}

.mobile {
  border-radius: 10px;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.bold {
  font-weight: 800;
}

.belt-text {
  z-index: 1;
}

.black-belt {
  background-color: #2c2c2c;
  background-color: var(--gray);
  height: 250px;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 30%;
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.bg-gray {
  background-color: #2c2c2c;
  background-color: var(--gray);
}

.h-auto {
  height: auto;
}

.landing-class-type {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 1;
  -webkit-filter: brightness(60%);
          filter: brightness(60%);
}

.landing-class-type:after {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.black-overlay {
  background-color: #000;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  opacity: 0.4;
  z-index: 0;
}

.class-type-description {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.landing-class-type-container {
  position: relative;
  overflow: hidden;
}
.landing-class-type-container:hover .landing-class-type {
  -webkit-filter: brightness(20%);
          filter: brightness(20%);
}

.landing-class-type-container:hover .landing-class-type:after {
  opacity: 0.4;
}

.landing-class-type-container:hover .class-type-description {
  opacity: 1;
  color: #fff;
}
.landing-class-type-container:hover .class-type-logo {
  opacity: 0;
}

.class-type-logo {
  position: absolute;
  top: 200px;
  width: 50%;
  height: 50px;
  object-fit: contain;
  left: 25%;
  text-align: center;
}

.shadow-right {
  box-shadow: 10px 10px 8px #888888;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.add-section-card {
  position: absolute;
  bottom: 0px;
  width: 101%;
  padding: 16px;
  margin-left: -15px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.mh-100 {
  max-height: 100%;
}

.section-wrapper {
  height: 100vh;
}

.show-mobile {
  display: none;
}

.lesson-container {
  min-height: 100vh;
  overflow-y: scroll;
}

.title-cont {
  max-width: 45%;
  text-align: left;
  padding: 32px;
  margin-top: -140px;
  color: #fff;
}

.card.lesson-card {
  height: 400px;
  border-radius: 0px;
  border: 3px solid black !important;
}

video.lesson-media {
  max-height: 300px;
}

.lesson-media-thumbnail {
  max-width: 100px;
}

a.lesson-media {
  position: relative;
  display: block;
  height: 300px;
}

img.lesson-media {
  max-height: 500px;
  object-fit: contain;
}

a.lesson-media img {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 50px);
}

a.lesson-media h4 {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 75px;
  left: 0px;
}

a.lesson-media p {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 15px;
  left: 0px;
}

.title {
  font-weight: 900;
  font-size: 10rem;
  max-width: 300px;
  line-height: 0.8em;
  letter-spacing: -10px;
  font-style: italic;
  text-shadow: 1px 1px 2px black;
}

.bottom-navbar {
  display: none;
  overflow: hidden;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
}

td.datetime {
  min-width: 150px;
}

#field-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#precios img {
  max-width: 1000px;
}

#login-card a {
  color: #cac7e0 !important;
}

#login-card .btn-primary {
  background-color: #cac7e0 !important;
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
}

@media (max-width: 1000px) {
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
}

@media (max-width: 767px) {
  .adilo-player {
    height: 50vh;
  }
  .bottom-navbar {
    display: block !important;
  }
  .card-type {
    max-width: 35px;
  }
  .main-panel h2 {
    font-size: 2rem;
  }
  .panel-content {
    padding: 16px;
  }
  .show-mobile {
    display: inherit;
  }
  .lesson-wrapper {
    padding-top: 0px;
  }
  .section-container {
    min-height: auto;
  }
  .section-wrapper {
    height: auto;
  }
  .main-panel {
    margin-left: 0px;
  }
  .panel-mobile-menu {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .main-panel {
    padding-left: 0px;
  }
  .text-funny {
    font-size: 32px;
  }
  .user-card {
    display: none;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
    font-family: "Montserrat", sans-serif !important;
    text-transform: capitalize !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .schedule-container {
    width: 100%;
  }
  .h-80 {
    height: auto;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 6em;
    max-width: 100%;
  }
  .title-cont {
    max-width: 100%;
    margin-top: -100px;
  }
}

@font-face {
  font-family: "Impact";
  src: url('https: //latinatribe.s3.us-west-1.amazonaws.com/Impact.woff') format("woff")
}

@font-face {
  font-family: "Libre Baskerville";
  src: url('https: //latinatribe.s3.us-west-1.amazonaws.com/LibreBaskerville-Regular.woff') format("woff")
}

@font-face {
  font-family: "Libre Baskerville";
  font-weight: bold;
  src: url('https: //latinatribe.s3.us-west-1.amazonaws.com/LibreBaskerville-Bold.woff') format("woff")
}

@font-face {
  font-family: "Libre Baskerville";
  font-style: italic;
  src: url('https: //latinatribe.s3.us-west-1.amazonaws.com/LibreBaskerville-Italic.woff') format("woff")
}

:root {
  --pink: #EDC0D3;
  --gray: #2c2c2c;
  --dark: #000;
  --accent: #ea638c;
  --butter: #f1e1b0;
  --yellow: #EFE0A3;
  --orange: #fbd5b7;
  --blue: #D4F1FA;
  --primary: #D4F1FA;
}

body {
  font-family: "Libre Baskerville", sans-serif;
}

section {
  display: flex;
  min-height: 95vh;
  align-items: center;
}

.archivo {
  font-family: "Archivo Black", sans-serif;
}

.btn-howtolatina {
  width: 250px;
}

ul.style-none {
  list-style: none;
  padding-left: 0px;
}

.libre {
  font-family: "Libre Baskerville", sans-serif !important;
}

.impact * {
  font-family: "Impact", sans-serif !important;
  font-weight: 400 !important;
}

h3.archivo {
  line-height: 1;
}

h2 {
  font-size: 3rem;
}

.home-page li {
  font-size: 1.2rem;
}

.text-small {
  font-size: 1rem;
}

.pink-card {
  background-color: #D4F1FA;
  background-color: var(--primary);
  padding: 24px;
  font-weight: bold;
  margin-top: -64px;
  margin-left: 64px;
  max-width: 400px;
  font-size: 1.4em;
  font-weight: bold;
}

.title-italic {
  font-size: 4em;
  font-weight: bolder;
  color: white;
  font-style: italic;
  text-shadow: 1px 1px 2px black;
}

.psst-title {
  margin-top: -100px;
}

.price-label {
  color: var(--purple);
  font-size: 2em;
  text-transform: uppercase;
  font-weight: bolder;
  line-height: 0.8em;
  margin-top: 24px;
}

.price {
  color: var(--purple);
  font-size: 6em;
  font-weight: bolder;
  line-height: 0.8em;
  margin-top: 12px;
}

.prev-price {
  color: #D4F1FA;
  color: var(--primary);
  text-decoration: line-through;
  font-size: 4em;
  font-weight: bolder;
}

.card-black {
  background-color: black;
  padding: 32px;
}

.card-black p {
  color: white;
  font-weight: bold;
  font-size: 1.4em;
  margin-bottom: 32px;
}

.text-primary {
  color: #D4F1FA !important;
  color: var(--primary) !important;
}

.text-blue {
  color: #D4F1FA;
  color: var(--blue)
}

.border-pink {
  border: 4px solid #D4F1FA;
  border: 4px solid var(--primary);
}

.bg-pink {
  background-color: #D4F1FA;
  background-color: var(--primary);
  color: #000;
}

.wyl {
  margin-top: -40vh;
}

.text-large {
  font-size: 1.4rem;
  letter-spacing: 0px;
}

span.text-large {
  font-size: 1.6em;
}

.text-stroke {
  text-decoration: line-through;
  -webkit-text-decoration-color: #D4F1FA;
          text-decoration-color: #D4F1FA;
  -webkit-text-decoration-color: var(--primary);
          text-decoration-color: var(--primary);
}

.subtitle {
  font-style: italic;
  font-weight: 600;
  text-shadow: 1px 1px 2px black;
}

.h-600 {
  height: 500px
}

section#welcome {
  min-height: 50vh;
}

@media (max-width: 992px) {
  .wyl {
    margin-top: 0px;
  }
}

@media (max-width: 576px) {
  .wyl {
    margin-top: 0px;
  }
}

.slideshow-img {
  height: 100vh;
  object-fit: cover;
}

.slideshow-text {
  color: #fff;
  position: absolute;
  top: 20%;
  padding: 16px;
  z-index: 10;
  width: 100%;
}

.slideshow-content {
  max-width: 650px;
  width: 100%;
}


.slideshow-text p.italic {
  font-size: 1.5rem;
}

.slideshow-text p {
  font-size: 1.2rem;
  max-width: 425px;
}

.carousel-overlay {
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 1) 40%,
      rgba(255, 255, 255, 0) 75%);
  opacity: 0.4;
  height: 100vh;
  position: absolute;
  display: block;
  top: 0;
  width: 75%;
  z-index: 1;
}

.howtolatina {
  max-width: 500px;
}

.video-background {
  background-image: url("https://latinatribe.s3.us-west-1.amazonaws.com/fondo-video-latina.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh
}

.home-page {
  color: #fff
}

.accordion-item h2 button {
  font-size: 1.6rem;
  background-color: #D4F1FA;
  background-color: var(--blue);
  font-family: "Libre Baskerville";
}

.accordion-button:not(.collapsed) {
  background-color: #D4F1FA;
  background-color: var(--blue);
  font-family: "Libre Baskerville";
}

.accordion-body {
  background-color: white;
  color: #000;
  color: var(--dark);
}

.accordion-button:not(.collapsed) {
  color: #000;
  color: var(--dark)
}

.slideshow-logo {
  max-width: 450px;
  max-height: 150px;
  object-fit: contain;
}

p.italic {
  font-style: italic;
}

.btn-lg {
  font-size: 1.2rem;
}

.home-page .card {
  border-radius: 0px;
  max-width: 500px;
  position: relative;
  border: 1px solid white;
  padding: 0px !important;
  background-color: transparent;
  
}

.home-page .card .content {
  text-align: center;
}

.home-page .card .badge {
  position: absolute;
  left: 0px;
  top: 10px;
  border-radius: 0px;
}

.home-page .card img {
  height: 300px;
  max-height: 300px;
  object-fit: cover;
}

.home-page #testimonios .card img {
  height: 500px;
  max-height: 500px;
  object-fit: cover;
}

p.description {
  height: 75px;
  text-overflow: ellipsis;
}

#welcome h2 {
  font-size: 8rem;
  font-weight: 400;
}

#welcome p {
  font-size: 1.8rem;
  max-width: 825px;
  margin: auto;
  display: block;
}

#howtolatina {
  background-image: url("https://latinatribe.s3.us-west-1.amazonaws.com/instructor-17-min.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
}

#certified-logo {
  max-width: 450px;
  max-height: 175px;
  object-fit: contain;
}

#instructor-logo {
  max-width: 450px;
  max-height: 125px;
  object-fit: contain;
}

#howtolatina-logo {
  max-width: 450px;
  max-height: 200px;
  object-fit: contain;
}

.home-page #testimonios .card {
  height: 850px;
}

@media (min-height: 1000px) {
  .home-page .card {
    height: 60vh;
  }
  .home-page .card .content {
    padding: 3rem !important;
  }

}

@media(max-height: 999px) {
  .home-page .card {
    height: 625px;
  }
  .home-page .card .content {
    padding: 2.5rem !important;
  }
  .home-page #instructor .card {
    height: 800px;
  }
  .home-page #testimonios .card {
    height: 800px;
  }
  .slideshow-logo {
    max-height: 100px;
  }
}

@media(max-width: 1200px) {
  h2 {
    font-size: 2rem;
  }

  .slideshow-text {
    top: 10%;
  }

  .slideshow-text p {
    font-size: 1rem;
  }
  #welcome h2 {
    font-size: 4.5rem;
  }
  #welcome p {
    max-width: 500px;
    font-size: 1.4rem;
  }
}

@media(max-width: 767px) {
  .home-page #trainings .card {
    height: 60vh;
  }
  .home-page #instructor .card {
    height: 700px;
  }
  section {
    min-height: 40vh;
  }
  #welcome h2 {
    font-size: 4.5rem;
  }
  #welcome p {
      font-size: 1.4rem;
  }
  h2 {
    font-size: 2rem;
  }
  .home-page .card {
    max-width: 100%;
  }
  .carousel-overlay {
    width: 200%;
  }
  .slideshow-text {
    max-width: 100%;
  }
  .p-5 {
    padding: 1.5rem !important;
  }
  .h-600 {
    height: auto;
  }
  .slideshow-logo {
    max-width: 350px;
  }
  .howtolatina {
    max-width: 100%;
  }
  .mobile-left {
    text-align: left;
  }
  .text-large {
    font-size: 1.2rem;
  }
  .accordion-item h2 button {
    font-size: 1.4rem;
  }
  .display-3 {
    font-size: 2rem;
  }
  .carousel-overlay {
    height: 70vh;
  }
  .slideshow-img {
    height: 70vh;
  }
  .w-75 {
    width: 100% !important;
  }
  .h3 {
    font-size: 1.2rem;
  }
}

@media(max-width: 450px) {
  .slideshow-text {
    top: 15% !important;
  }
  .home-page #trainings .card {
    height: 70vh;
  }
}
