@font-face {
  font-family: "Impact";
  src: url('https: //latinatribe.s3.us-west-1.amazonaws.com/Impact.woff') format("woff")
}

@font-face {
  font-family: "Libre Baskerville";
  src: url('https: //latinatribe.s3.us-west-1.amazonaws.com/LibreBaskerville-Regular.woff') format("woff")
}

@font-face {
  font-family: "Libre Baskerville";
  font-weight: bold;
  src: url('https: //latinatribe.s3.us-west-1.amazonaws.com/LibreBaskerville-Bold.woff') format("woff")
}

@font-face {
  font-family: "Libre Baskerville";
  font-style: italic;
  src: url('https: //latinatribe.s3.us-west-1.amazonaws.com/LibreBaskerville-Italic.woff') format("woff")
}

:root {
  --pink: #EDC0D3;
  --gray: #2c2c2c;
  --dark: #000;
  --accent: #ea638c;
  --butter: #f1e1b0;
  --yellow: #EFE0A3;
  --orange: #fbd5b7;
  --blue: #D4F1FA;
  --primary: #D4F1FA;
}

body {
  font-family: "Libre Baskerville", sans-serif;
}

section {
  display: flex;
  min-height: 95vh;
  align-items: center;
}

.archivo {
  font-family: "Archivo Black", sans-serif;
}

.btn-howtolatina {
  width: 250px;
}

ul.style-none {
  list-style: none;
  padding-left: 0px;
}

.libre {
  font-family: "Libre Baskerville", sans-serif !important;
}

.impact * {
  font-family: "Impact", sans-serif !important;
  font-weight: 400 !important;
}

h3.archivo {
  line-height: 1;
}

h2 {
  font-size: 3rem;
}

.home-page li {
  font-size: 1.2rem;
}

.text-small {
  font-size: 1rem;
}

.pink-card {
  background-color: var(--primary);
  padding: 24px;
  font-weight: bold;
  margin-top: -64px;
  margin-left: 64px;
  max-width: 400px;
  font-size: 1.4em;
  font-weight: bold;
}

.title-italic {
  font-size: 4em;
  font-weight: bolder;
  color: white;
  font-style: italic;
  text-shadow: 1px 1px 2px black;
}

.psst-title {
  margin-top: -100px;
}

.price-label {
  color: var(--purple);
  font-size: 2em;
  text-transform: uppercase;
  font-weight: bolder;
  line-height: 0.8em;
  margin-top: 24px;
}

.price {
  color: var(--purple);
  font-size: 6em;
  font-weight: bolder;
  line-height: 0.8em;
  margin-top: 12px;
}

.prev-price {
  color: var(--primary);
  text-decoration: line-through;
  font-size: 4em;
  font-weight: bolder;
}

.card-black {
  background-color: black;
  padding: 32px;
}

.card-black p {
  color: white;
  font-weight: bold;
  font-size: 1.4em;
  margin-bottom: 32px;
}

.text-primary {
  color: var(--primary) !important;
}

.text-blue {
  color: var(--blue)
}

.border-pink {
  border: 4px solid var(--primary);
}

.bg-pink {
  background-color: var(--primary);
  color: #000;
}

.wyl {
  margin-top: -40vh;
}

.text-large {
  font-size: 1.4rem;
  letter-spacing: 0px;
}

span.text-large {
  font-size: 1.6em;
}

.text-stroke {
  text-decoration: line-through;
  text-decoration-color: var(--primary);
}

.subtitle {
  font-style: italic;
  font-weight: 600;
  text-shadow: 1px 1px 2px black;
}

.h-600 {
  height: 500px
}

section#welcome {
  min-height: 50vh;
}

@media (max-width: 992px) {
  .wyl {
    margin-top: 0px;
  }
}

@media (max-width: 576px) {
  .wyl {
    margin-top: 0px;
  }
}

.slideshow-img {
  height: 100vh;
  object-fit: cover;
}

.slideshow-text {
  color: #fff;
  position: absolute;
  top: 20%;
  padding: 16px;
  z-index: 10;
  width: 100%;
}

.slideshow-content {
  max-width: 650px;
  width: 100%;
}


.slideshow-text p.italic {
  font-size: 1.5rem;
}

.slideshow-text p {
  font-size: 1.2rem;
  max-width: 425px;
}

.carousel-overlay {
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 1) 40%,
      rgba(255, 255, 255, 0) 75%);
  opacity: 0.4;
  height: 100vh;
  position: absolute;
  display: block;
  top: 0;
  width: 75%;
  z-index: 1;
}

.howtolatina {
  max-width: 500px;
}

.video-background {
  background-image: url("https://latinatribe.s3.us-west-1.amazonaws.com/fondo-video-latina.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh
}

.home-page {
  color: #fff
}

.accordion-item h2 button {
  font-size: 1.6rem;
  background-color: var(--blue);
  font-family: "Libre Baskerville";
}

.accordion-button:not(.collapsed) {
  background-color: var(--blue);
  font-family: "Libre Baskerville";
}

.accordion-body {
  background-color: white;
  color: var(--dark);
}

.accordion-button:not(.collapsed) {
  color: var(--dark)
}

.slideshow-logo {
  max-width: 450px;
  max-height: 150px;
  object-fit: contain;
}

p.italic {
  font-style: italic;
}

.btn-lg {
  font-size: 1.2rem;
}

.home-page .card {
  border-radius: 0px;
  max-width: 500px;
  position: relative;
  border: 1px solid white;
  padding: 0px !important;
  background-color: transparent;
  
}

.home-page .card .content {
  text-align: center;
}

.home-page .card .badge {
  position: absolute;
  left: 0px;
  top: 10px;
  border-radius: 0px;
}

.home-page .card img {
  height: 300px;
  max-height: 300px;
  object-fit: cover;
}

.home-page #testimonios .card img {
  height: 500px;
  max-height: 500px;
  object-fit: cover;
}

p.description {
  height: 75px;
  text-overflow: ellipsis;
}

#welcome h2 {
  font-size: 8rem;
  font-weight: 400;
}

#welcome p {
  font-size: 1.8rem;
  max-width: 825px;
  margin: auto;
  display: block;
}

#howtolatina {
  background-image: url("https://latinatribe.s3.us-west-1.amazonaws.com/instructor-17-min.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
}

#certified-logo {
  max-width: 450px;
  max-height: 175px;
  object-fit: contain;
}

#instructor-logo {
  max-width: 450px;
  max-height: 125px;
  object-fit: contain;
}

#howtolatina-logo {
  max-width: 450px;
  max-height: 200px;
  object-fit: contain;
}

.home-page #testimonios .card {
  height: 850px;
}

@media (min-height: 1000px) {
  .home-page .card {
    height: 60vh;
  }
  .home-page .card .content {
    padding: 3rem !important;
  }

}

@media(max-height: 999px) {
  .home-page .card {
    height: 625px;
  }
  .home-page .card .content {
    padding: 2.5rem !important;
  }
  .home-page #instructor .card {
    height: 800px;
  }
  .home-page #testimonios .card {
    height: 800px;
  }
  .slideshow-logo {
    max-height: 100px;
  }
}

@media(max-width: 1200px) {
  h2 {
    font-size: 2rem;
  }

  .slideshow-text {
    top: 10%;
  }

  .slideshow-text p {
    font-size: 1rem;
  }
  #welcome h2 {
    font-size: 4.5rem;
  }
  #welcome p {
    max-width: 500px;
    font-size: 1.4rem;
  }
}

@media(max-width: 767px) {
  .home-page #trainings .card {
    height: 60vh;
  }
  .home-page #instructor .card {
    height: 700px;
  }
  section {
    min-height: 40vh;
  }
  #welcome h2 {
    font-size: 4.5rem;
  }
  #welcome p {
      font-size: 1.4rem;
  }
  h2 {
    font-size: 2rem;
  }
  .home-page .card {
    max-width: 100%;
  }
  .carousel-overlay {
    width: 200%;
  }
  .slideshow-text {
    max-width: 100%;
  }
  .p-5 {
    padding: 1.5rem !important;
  }
  .h-600 {
    height: auto;
  }
  .slideshow-logo {
    max-width: 350px;
  }
  .howtolatina {
    max-width: 100%;
  }
  .mobile-left {
    text-align: left;
  }
  .text-large {
    font-size: 1.2rem;
  }
  .accordion-item h2 button {
    font-size: 1.4rem;
  }
  .display-3 {
    font-size: 2rem;
  }
  .carousel-overlay {
    height: 70vh;
  }
  .slideshow-img {
    height: 70vh;
  }
  .w-75 {
    width: 100% !important;
  }
  .h3 {
    font-size: 1.2rem;
  }
}

@media(max-width: 450px) {
  .slideshow-text {
    top: 15% !important;
  }
  .home-page #trainings .card {
    height: 70vh;
  }
}